<template>
  <div class="view-wrap">
    <div class="button">
      <el-button type="primary" round @click="UpDate">
        <i class="el-icon-plus" /> 新增</el-button
      >
    </div>
    <div class="view">
      <SimpleTable
        :data="tableData"
        :tableFields="tableItem"
        :operation="operationEvent"
        :currentPage="searcForm.pageNum"
        :pageSize="searcForm.pageSize"
        :total="total"
        @sizeChange="changePageSize"
        @currentChange="changeCurrentPage"
      >
        <div slot="operation" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            content="修改"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="EditData(row.data)"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
          <!--  -->
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="DeleteData(row.data)"
              ><i class="el-icon-delete"></i
            ></el-button>
          </el-tooltip>
          <!--  -->
          <el-tooltip
            class="item"
            effect="dark"
            content="菜单授权"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="setting(row.data)"
              ><i class="el-icon-setting"></i
            ></el-button>
          </el-tooltip>
        </div>
      </SimpleTable>
    </div>
    <miaDialog ref="AddOrEditForm" v-if="Visible">
      <div slot="content">
        <el-form
          ref="form"
          style="width: 70%; margin: 0 auto"
          :model="submitForm"
          :rules="submitRules"
          label-width="80px"
        >
          <el-form-item label="角色名称" prop="name" key="name">
            <el-input
              v-model="submitForm.name"
              placeholder="请输入角色名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色介绍" prop="introduce" key="introduce">
            <el-input
              type="textarea"
              :rows="2"
              v-model="submitForm.introduce"
              placeholder="请输入角色介绍"
            ></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitData">提交</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
    <miaDialog ref="AuthorizedForm" v-if="AuthorizedVisible">
      <div slot="content">
        <el-tree
          ref="tree"
          class="treeContent"
          :data="authData"
          show-checkbox
          node-key="menuId"
          default-expand-all
          :check-strictly="true"
          :props="defaultProps"
          @check="nodeCheck"
          :default-expanded-keys="defaultChecked"
        />
        <!-- @check-change="checkChange" -->
        <div style="width: 100%; text-align: center; margin-top: 50px">
          <el-button type="primary" @click="submitAuthorized">提交</el-button>
          <el-button @click="closeAuthorized">取消</el-button>
        </div>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import {
  getRoleList,
  saveRole,
  EditRole,
  deleteRole,
  GetAuthorized,
  EditAuthorized,
  getMenus,
} from "@/api/role";
export default {
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkAuthData:[],
      needChecked: [],
      tableData: [],
      tableItem: [
        { prop: "name", label: "名称", type: "default" },
        {
          prop: "type",
          label: "类型",
          type: "roleType",
          showTextList: ["默认角色", "自定义角色"],
        },
        {
          prop: "introduce",
          label: "角色介绍",
          type: "default",
        },
        {
          prop: "number",
          label: "人员数量",
          type: "number",
        },
        { label: "操作", type: "operation" },
      ],
      operationEvent: [
        { type: "edit", event: this.EditData },
        { type: "delete", event: this.DeleteData },
        { type: "setting", event: this.setting },
      ],
      Visible: false,
      AuthorizedVisible: false,
      authData: [],
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      oldRoleData: [],
      defaultChecked: [],
      currentAuthData: [],
      sourceData: [],
      checkStrictly: true,
      submitForm: {
        id: null,
        name: "",
        introduce: "",
        offices: [],
      },
      submitRules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        introduce: [
          {
            min: 0,
            max: 50,
            message: "角色介绍在 0 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getRoleList(this.searcForm).then((res) => {
        const { records, current, total } = res.data;
        if (res.code === 1) {
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 新增
    UpDate() {
      this.submitForm = {
        id: null,
        name: "",
        offices: [],
      };
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("新建角色");
      });
    },
    // 提交
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.submitForm.id === null) {
            saveRole(this.submitForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          } else {
            EditRole(this.submitForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    EditData(data) {
      this.submitForm.id = data.id;
      this.submitForm.name = data.name;
      this.submitForm.introduce = data.introduce;
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("修改角色");
      });
    },
    DeleteData(data) {
      this.$confirm(`是否删除该角色？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        deleteRole(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
    submitAuthorized() {
      const nodes = this.$refs.tree.getCheckedNodes();
      this.checkAuthData = [];
      nodes.map((val) => {
        this.checkAuthData.push({
          roleId: this.currentAuthData.id,
          menuId: val.menuId,
        });
      });
      if (this.checkAuthData.length === 0) {
        this.$message.error("请选择授权菜单");
        return;
      }
      this.checkAuthData = this.checkAuthData.concat(this.needChecked);
      EditAuthorized(this.checkAuthData).then((res) => {
        if (res.code === 1) {
          this.$message.success("授权成功");
          this.getData();
          this.closeAuthorized();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    closeAuthorized() {
      this.$refs.AuthorizedForm.close();
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    /**
     * 处理list转树结构
     */ convertListToTree(arr, root) {
      const stack = [];
      const array = [];
      let start = false;
      stack.push({ ...root });
      while (stack.length !== 0) {
        const node = stack.pop();
        const children = arr.filter((item) => {
          return item.parent && item.parent === node.menuId;
        });
        node.children = children;
        if (children.length > 0) {
          node.hasChildren = true;
          for (let i = children.length - 1; i >= 0; i--) {
            stack.push(children[i]);
          }
        }
        if (!start) {
          start = true;
          array.push(node);
        }
      }
      return array;
    },
    /**
     * 树结构转list
     */
    convertTreeToList(root) {
      const stack = [];
      const array = [];
      const hashMap = {};
      stack.push(root);
      while (stack.length !== 0) {
        const node = stack.pop();
        const children = node.children;
        if (node.children.length > 0) {
          for (let i = children.length - 1; i >= 0; i--) {
            stack.push(children[i]);
          }
        }
        this.visitNode(node, hashMap, array);
      }
      return array;
    },
    //授权
    setting(data) {
      this.AuthorizedVisible = true;
      this.$nextTick(() => {
        this.$refs.AuthorizedForm.init("角色授权");
      });
      GetAuthorized(data.id).then((res) => {
        if (res.code === 1) {
          this.currentAuthData = data;
          this.sourceData = res.data;
          this.defaultChecked = [];
          let arr = [];
          const ids = [];
          if (res.data && res.data.length > 0) {
            res.data.map((val) => {
              ids.push(val.menuId);
              if (val.parent === 0) {
                arr = arr.concat(this.convertListToTree(res.data, val));
              }
              if (val.roleId) {
                this.defaultChecked.push(val.menuId);
              }
            });
          }
          this.authData = arr;
          // 默认勾选的字典查询、机构查询等
          const needChecked = [];
          res.data.map((val) => {
            if (val.parent !== 0 && ids.indexOf(val.parent) === -1) {
              needChecked.push({
                menuId: val.menuId,
                roleId: this.currentAuthData.id,
              });
            }
          });
          this.needChecked = needChecked;
          this.$refs.tree.setCheckedKeys(this.defaultChecked);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    checkChange(data, check, child) {
      if (check && this.defaultChecked.indexOf(data.menuId) === -1) {
        this.defaultChecked.push(data.menuId);
        this.oldRoleData.push({
          roleId: this.currentAuthData.id,
          menuId: data.menuId,
        });
      }
      if (!check && this.defaultChecked.indexOf(data.menuId) > -1) {
        const index = this.defaultChecked.indexOf(data.menuId);
        let indexNum = null;
        this.oldRoleData.map((val, n) => {
          if (val.menuId === data.menuId) {
            indexNum = n;
          }
        });
        if (indexNum !== null) {
          this.oldRoleData.splice(indexNum, 1);
        }
        this.defaultChecked.splice(index, 1);
      }
    },
    nodeCheck(data, e) {
      const nowKeys = e.checkedKeys;
      if (nowKeys.indexOf(data.menuId) === -1) {
        let index = null;
        this.oldRoleData.map((val, n) => {
          if (val.menuId === data.menuId) {
            index = n;
          }
        });
        if (index !== null) {
          this.oldRoleData.splice(index, 1);
        }
      }
      // 定义children的最终结果
      let childrenResults;
      if (data.children.length > 0) {
        const temp = [];
        const stack = [data];
        while (stack.length !== 0) {
          const node = stack.pop();

          node.children.forEach((val) => {
            temp.push(val.menuId);
            if (val.children && val.children.length > 0) {
              stack.push(val);
            }
          });
        }
        if (nowKeys.indexOf(data.menuId) === -1) {
          const result = nowKeys.filter((val) => temp.indexOf(val) === -1);

          childrenResults = result;
        } else {
          childrenResults = nowKeys.concat(temp);
        }
      }
      // 定义父节点的最终结果
      const parents = [];
      if (data.parent && data.isButton !== "1") {
        parents.push(data.parent);
        let results = [];
        let parent = data.parent;
        while (parent !== 0) {
          results = this.sourceData.filter((val) => val.menuId === parent);
          parent = results.length > 0 ? results[0].parent : 0;
          parent !== 0 && parents.push(parent);
        }
      }
      this.$refs.tree.setCheckedKeys(childrenResults.concat(parents));
    },
  },
};
</script>
<style lang="scss" scoped>
.treeContent {
  height: 50vh;
  overflow-y: auto;
}
</style>